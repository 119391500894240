<template>
  <div class="progress-bar">
    <div class="bar" :style="'width:' + progress + '%'" :class="barHeight"/>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    value: Number,
    barHeight: {
      type: String,
      default: 'bar-height-small'
    }
  },
  setup(props) {
    const progress = computed(() => (isNaN(props.value) ? 0 : props.value));

    return {
      progress,
    };
  },
};
</script>
